'use strict';

// PAYMENT • Export module

export const payment = () => {
    discount();
};

// PAYMENT • Create discount

const discount = () => {
    $('body').on('click', '.payment-discount-button', function(){
        let parentItem = $(this).closest('.payment-discount-item');
        let discountForm = parentItem.find('.payment-discount-form');

        if(discountForm) {
            discountForm.slideDown(500);
        }
    });
};