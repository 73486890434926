'use strict';

// ANIMATE • Export module

export const animate = () => {
    let win = $(window);
    let winHeight = win.height();

    let animHead = $('.animate-head');
    let animElements = $('.animate-element');

    const animationEvent = (elements, yPosition) => {
        const winTopPos = yPosition + winHeight;

        elements.each(function(i, elem){
            let element = $(elem);

            const elemHeight = element.height();
            const elemTop = element.offset().top;
            const elemTopPos = (elemTop + elemHeight);

            if((elemTopPos >= yPosition) && (elemTop <= winTopPos)) {
                setTimeout(function(){
                    element.addClass('visible');

                    setTimeout(() => {
                        element.addClass('finished');
                    }, 1500);
                }, 0);
            }
        });
    };

    animHead.addClass('visible');

    setTimeout(function(){
        animationEvent(animElements, 0);
    }, 100);

    setTimeout(function(){
        animHead.addClass('finished');
    }, 1500);

    win.scroll(function(){
        let position = $(document).scrollTop();

        animationEvent(animElements, position);
    });

    win.resize(function(){
        winHeight = win.height();
    });
};