'use strict';

// CATALOG • Export module

export const catalog = () => {
    search();
    display();
};

// CATALOG • Create search

const search = () => {
    const formSearch = $('#search-catalog-form');

    if(formSearch.length) {
        const inputSearch = $('#search-catalog');
        const cleanSearch = $('#clean-catalog-search');

        const bindInput = (value) => {
            if(value !== '') {
                cleanSearch.addClass('active');
            } else {
                cleanSearch.removeClass('active');
            }
        };

        bindInput(inputSearch.val());

        inputSearch.keyup(function(){
            bindInput($(this).val())
        });

        cleanSearch.on('click', function(){
            inputSearch.val('');
            $(this).removeClass('active');
        });
    }
};

// CATALOG • Create display

const display = () => {
   let allow = true;

   const sortItems = $('.sort-display');
   const content = $('.section-catalog-content');
   const containers = $('.section-catalog-container');

   $('body').on('click', '.sort-display', function(){
       let index = $(this).index();
       let contentHeight = content.height();

       if(allow === true) {
           allow = false;

           sortItems.removeClass('active');
           $(this).addClass('active');

           content.addClass('hidden');
           content.height(contentHeight);

           setTimeout(function(){
               allow = true;

               containers.removeClass('active');
               containers.eq(index).addClass('active');

               content.removeClass('hidden');
               content.css('height', 'auto');
           }, 500);
       }

       return false;
   });
};