'use strict';

// APP • Import modules

import {basics} from './modules/basics/basics';
import {pages} from './modules/pages/pages';

// APP • Create app

const app = () => {
    basics();
    pages();
};

// APP • Launching

document.addEventListener('DOMContentLoaded', app);