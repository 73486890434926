'use strict';

// DELIVERY • Export module

export const delivery = () => {
    content();
};

// DELIVERY • Create content

const content = () => {
    let current = 0;
    let allow = true;

    const content = $('.delivery-content');
    const items = $('.delivery-content-item');
    const selectCountry = $('#delivery-country');

    selectCountry.on('change', function(){
        let selectValue = $(this).val();
        let selectOptions = selectCountry.find('option');

        for(let i = 0; i < selectOptions.length; i++) {
            if($(selectOptions[i]).text() === selectValue) {
                selectCountry.attr('data-select', i);
            }
        }
    });

    $('body').on('click', '#select-delivery-form', function(){
        let dataSelect = parseInt(selectCountry.attr('data-select'));

        if(current !== dataSelect) {
            let contentHeight = content.height();

            allow = false;
            current = dataSelect;
            content.addClass('hidden');
            content.height(contentHeight);

            setTimeout(function(){
                items.removeClass('active');
                items.eq(current).addClass('active');

                content.css('height', 'auto');
                content.removeClass('hidden');
            }, 500);

            setTimeout(function(){
                allow = true;
            }, 500);
        }
    });
};