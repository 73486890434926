'use strict';

// INDEX • Import module

import Swiper from 'swiper';

// INDEX • Export module

export const index = () => {
    reviews();
};

// INDEX • Create reviews

const reviews = () => {
    const reviewsName = '.reviews-slider';
    const reviewsElement = $(reviewsName);

    if(reviewsElement) {
        new Swiper(reviewsName, {
            speed: 500,
            loop: true,
            spaceBetween: 0,
            autoHeight: true,
            pagination: {
                clickable: true,
                el: '.reviews-pagination'
            },
            breakpoints: {
                1200: {spaceBetween: 50}
            },
        });
    }
};