'use strict';

// PAGES • Import modules

import {index} from './includes/index';
import {contact} from './includes/contact';
import {catalog} from './includes/catalog';
import {product} from './includes/product';
import {payment} from './includes/payment';
import {delivery} from './includes/delivery';

import {animate} from './animate';

// PAGES • Export module

export const pages = () => {
    const dataPage = $('#unit-container').attr('data-page');

    switch(dataPage) {
        case 'index': index(); break;
        case 'contact': contact(); break;
        case 'catalog': catalog(); break;
        case 'product': product(); break;
        case 'payment': payment(); break;
        case 'delivery': delivery(); break;
    }

    setTimeout(function(){
        $('body').addClass('loaded');
        $('html, body').animate({scrollTop: 0}, 0);
    }, 500);

    setTimeout(function(){
        animate();
    }, 1000);

    setTimeout(function(){
        $('body').addClass('uploaded');
    }, 2000);
};