'use strict';

// MATERIALS • Import modules

import Swiper from 'swiper';
import '../../minify/rateyo';
import Instafeed from 'instafeed.js';
import niceSelect from 'jquery-nice-select';

// MATERIALS • Export module

export const materials = () => {
    ceo();
    rate();
    card();
    menu();
    header();
    filter();
    meters();
    selects();
    catalog();
    statics();
    instagram();
};

// MATERIALS • Create ceo

const ceo = () => {
    const container = $('.ceo-container');

    if(container) {
        let open = false;
        let allow = true;

        const ceoInner = $('.ceo-inner');
        const ceoHidden = $('.ceo-hidden');

        let hiddenHeight = 300;
        let ceoInnerHeight = ceoInner.css('height', 'auto').height();

        if(ceoInnerHeight > (hiddenHeight * 1.25)) {
            ceoHidden.addClass('active');
            ceoInner.height(hiddenHeight * 1.25);
        } else {
            ceoInner.css('height', 'auto');
        }

        $('body').on('click', '.show-more-ceo', function(){
            let that = $(this);

            if(allow === true) {
                allow = !allow;

                if(!open) {
                    open = !open;
                    ceoHidden.addClass('open');

                    ceoInner.animate({
                        height: ceoInnerHeight
                    }, 500, function(){
                        allow = !allow;
                        that.attr('title', 'Show less');
                    });
                } else {
                    ceoHidden.removeClass('open');

                    ceoInner.animate({
                        height: hiddenHeight * 1.25
                    }, 500, function(){
                        open = !open;
                        allow = !allow;
                        that.attr('title', 'Show more');
                    });
                }
            }
        });

        $(window).resize(function(){
            ceoInnerHeight = ceoInner.css('height', 'auto').height();

            if(ceoInnerHeight > (hiddenHeight * 1.25)) {
                if(!ceoHidden.hasClass('open')) {
                    ceoHidden.addClass('active');
                    ceoInner.height(hiddenHeight * 1.25);
                } else {
                    ceoInner.css('height', 'auto');
                }
            } else {
                ceoHidden.removeClass('active');
                ceoInner.css('height', 'auto');
            }
        });
    }
};

// MATERIALS • Create rate

const rate = () => {
    const rates = $('.section-rate');

    if(rates) {
        for(let i = 0; i < rates.length; i++) {
            let idRate = 'section-rate' + i;
            let countRate = rates.eq(i).attr('data-rate');

            rates.eq(i).attr('id', idRate);
            countRate !== '' ? parseFloat(countRate) : 0;

            $('#' + idRate).rateYo({
                rating: countRate,
                spacing: '1px',
                starWidth: '14px',
                ratedFill: '#FFDA18',
                normalFill: '#CFCFCF'
            });
        }
    }
};

// MATERIALS • Create card

const card = () => {
    const body = $('body');
    const cardModal = $('#card-modal');
    const menuModal = $('#menu-modal');

    body.on('click', '.open-card', function(){
        body.addClass('locked');
        cardModal.addClass('opened');
    });

    body.on('click', '.close-card', function(){
        cardModal.removeClass('opened');

        setTimeout(function(){
            if(!menuModal.hasClass('opened')) {
                body.removeClass('locked');
            }
        }, 1000);
    });

    body.on('click', '.card-item-minus', function(){
        let container = $(this).closest('.card-item-amount');
        let counter = container.find('.card-item-counter');

        let counterVal = parseInt(counter.text());

        if(counterVal > 1) {
            counter.text(counterVal - 1);
        }
    });

    body.on('click', '.card-item-plus', function(){
        let container = $(this).closest('.card-item-amount');
        let counter = container.find('.card-item-counter');

        let counterVal = parseInt(counter.text());

        if(counterVal < 99) {
            counter.text(counterVal + 1);
        }
    });
};

// MATERIALS • Create menu

const menu = () => {
    let exitMenu = true;

    const body = $('body');
    const menuModal = $('#menu-modal');

    const menuRoutes = $('.menu-modal-routes');
    const menuFocus = $('.menu-modal-focus');
    const menuResult = $('.menu-modal-result');
    const menuFooter = $('.menu-modal-footer');

    const tabs = $('.menu-modal-tab');
    const field = $('#search-menu');
    const caseInput = $('.menu-search-case');
    const container = $('.menu-modal-content-inner');

    const switchTab = function(newTab) {
        let heightContainer = container.height();

        container.height(heightContainer);

        tabs.removeClass('active');
        menuFooter.addClass('hidden');

        setTimeout(function(){
            newTab.addClass('active');
            menuFooter.removeClass('hidden');
        }, 500);

        setTimeout(function(){
            container.css({'height': 'auto'});
        }, 505);
    };

    body.on('click', '.menu-search-case', function(){
        let that = $(this);

        if(!that.hasClass('active')) {
            that.addClass('active');
            field.focus();

            switchTab(menuFocus);
        }
    });

    field.on('focus', function(){
        if(!caseInput.hasClass('active')) {
            caseInput.addClass('active');
            field.focus();

            if(field.val() === '') {
                switchTab(menuFocus);
            }
        }
    });

    field.on('keyup', function(){
        let that = $(this);

        if(that.val() !== '') {
            if(menuFocus.hasClass('active')) {
                switchTab(menuResult);
            }
        } else {
            exitMenu = true;

            if(!menuFocus.hasClass('active')) {
                switchTab(menuFocus);
            }
        }
    });

    body.on('click', '.open-menu', function(){
        exitMenu = true;
        body.addClass('locked');
        menuModal.addClass('opened');
    });

    body.on('click', '.open-search', function(){
        field.focus();
        exitMenu = false;

        body.addClass('locked');
        menuModal.addClass('opened');
        caseInput.addClass('active');

        if(field.val() === '') {
            switchTab(menuFocus);
        } else {
            switchTab(menuResult);
        }
    });

    body.on('click', '.close-menu', function() {
        exitMenu = false;
        menuModal.removeClass('opened');

        setTimeout(function(){
            body.removeClass('locked');
        }, 500);
    });

    $(document).click(function(event) {
        if (!$(event.target).is('.menu-search-case, #search-menu, #search-menu-button') && exitMenu) {
            if(field.val() === '' && caseInput.hasClass('active')) {
                switchTab(menuRoutes);
                caseInput.removeClass('active');
            }
        }
    });
};

// MATERIALS • Create header

const header = () => {
    const body = $('body');
    const lists = $('.select-list');
    const buttons = $('.select-button');

    body.on('click', '.select-button', function() {
        let that = $(this);
        let dataList = that.attr('data-list');
        let elementList = $('#' + dataList);

        if(elementList.hasClass('opened')) {
            that.removeClass('active');
            elementList.removeClass('opened');
        } else {
            lists.removeClass('opened');
            buttons.removeClass('active');

            if(elementList && !that.hasClass('active')) {
                that.addClass('active');
                elementList.addClass('opened');
            } else if(that.hasClass('active')) {
                buttons.removeClass('active');
            }
        }
    });

    $(document).click(function(event) {
        if (!$(event.target).is('.select-button')) {
            buttons.removeClass('active');
            lists.removeClass('opened');
        }
    });
};

// MATERIALS • Create filter

const filter = () => {
    let moved = false;

    const body = $('body');
    const footer = $('#footer');

    const filter = $('.section-filter-wrapper');
    const filterEmpty = $('.section-empty-filter');

    function moveFilter(width) {
        if(width > 1200 && filter.length) {
            filter.css({'opacity': '1'});

            if(filter.hasClass('opened')) {
                body.removeClass('locked');
                filter.removeClass('opened');
            }

            if(moved) {
                moved = false;
                filter.insertAfter(filterEmpty);
            }
        } else {
            filter.css({'opacity': '0'});

            if(!moved) {
                moved = true;
                filter.insertBefore(footer);
            }
        }
    }

    body.on('click', '.open-filter-mobile', function(){
        filter.css({'opacity': '1'});

        body.addClass('locked');
        filter.addClass('opened');
    });

    body.on('click', '.close-filter-mobile, #apply-filter', function(){
        filter.removeClass('opened');

        setTimeout(function(){
            body.removeClass('locked');
            filter.css({'opacity': '0'});
        }, 1000);
    });

    moveFilter($(window).width());

    $(window).resize(function(){
        moveFilter($(window).width());
    });
};

// MATERIALS • Create meters

const meters = () => {
    const body = $('body');
    const meterInput = $('.meters-input');

    body.on('click', '.meters-button-plus', function(){
        let valueInput = parseInt(meterInput.val());

        meterInput.val(valueInput + 1);
    });

    body.on('click', '.meters-button-minus', function(){
        let valueInput = parseInt(meterInput.val());

        if(valueInput > 1) {
            meterInput.val(valueInput - 1);
        }
    });

    meterInput.blur(function(){
        let valueInput = $(this).val();
        let valueLetters = valueInput.split('');

        if(valueInput === '' ||
            parseInt(valueInput) === 0 ||
            parseInt(valueLetters[0]) === 0 && valueInput.split('').length > 1) {
            $(this).val(1);
        }
    });

    meterInput.keydown(function(e) {
        if($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            return;
        }

        if((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });
};

// MATERIALS • Create selects

const selects = () => {
    const containers = $('select');

    if(containers) {
        containers.niceSelect();
    }
};

// MATERIALS • Create catalog

const catalog = () => {
    let name = 'catalog-slider';
    let element = $('.' + name);

    if(element) {
        for(let i = 0; i < element.length; i++) {
            let topOffset = 0;
            let catalogId = name + i;

            element.eq(i).attr('id', catalogId);

            let slider = $('#' + catalogId);
            let image = slider.find('.catalog-image')[0];
            let imageElement = $(image).find('img');

            let prev = slider.parent().find('.catalog-slide-button-prev');
            let next = slider.parent().find('.catalog-slide-button-next');
            let pagination = slider.parent().find('.catalog-pagination');

            $(pagination).addClass('catalog-pagination' + i);

            const swiper = new Swiper('#' + catalogId, {
                speed: 500,
                loop: true,
                spaceBetween: 30,
                slidesPerView: 4,
                pagination: {
                    clickable: true,
                    el: '.catalog-pagination' + i
                },
                breakpoints: {
                    1680: {spaceBetween: 25},
                    1200: {slidesPerView: 2},
                    640: {slidesPerView: 1}
                },
                on: {
                    init: () => {
                        const offsets = () => {
                            topOffset = $(imageElement).height() / 2 - $(prev).height() / 2;

                            $(prev).css('top', topOffset.toFixed(0) + 'px');
                            $(next).css('top', topOffset.toFixed(0) + 'px');
                        };

                        offsets();

                        $(prev).on('click', function(){
                            swiper.slidePrev();
                        });

                        $(next).on('click', function(){
                            swiper.slideNext();
                        });

                        $(window).resize(function(){
                            setTimeout(function(){
                                offsets();
                            }, 0);
                        });
                    }
                }
            });
        }
    }
};

// MATERIALS • Create statics

const statics = () => {
    const body = $('body');
    const modalsStatic = $('.modal-static');

    const modalMenu = $('.menu-modal');
    const modalLogin = $('#modal-login');
    const modalRegistration = $('#modal-registration');

    body.on('click', '.open-login', function(){
        let timeout = 0;

        if(modalsStatic.hasClass('opened')) {
            timeout = 1500;
            modalsStatic.removeClass('opened');
        }

        setTimeout(function(){
            body.addClass('locked');
            modalLogin.addClass('opened');
        }, timeout);

        return false;
    });

    body.on('click', '.open-registration', function(){
        let timeout = 0;

        if(modalsStatic.hasClass('opened')) {
            timeout = 1500;
            modalsStatic.removeClass('opened');
        }

        setTimeout(function(){
            body.addClass('locked');
            modalRegistration.addClass('opened');
        }, timeout);

        return false;
    });

    body.on('click', '#close-login', function(){
        modalLogin.removeClass('opened');

        setTimeout(function(){
            if(!modalMenu.hasClass('opened')) {
                body.removeClass('locked');
            }
        }, 1500);
    });

    body.on('click', '#close-registration', function(){
        modalRegistration.removeClass('opened');

        setTimeout(function(){
            if(!modalMenu.hasClass('opened')) {
                body.removeClass('locked');
            }
        }, 1500);
    });
};

// MATERIALS • Create instagram

const instagram = () => {
    const container = $('#instafeed');

    if(container) {
        const userFeed = new Instafeed({
            get: 'user',
            userId: '2279082729',
            clientId: 'fcad615174e744bfb7fceec69c6e9fe8',
            accessToken: '2279082729.1677ed0.268e540c6b7245f38e426343e4fe5884',
            resolution: 'standard_resolution',
            template: '<a href="{{link}}" title="Show more" id="{{id}}" target="_blank" class="footer-image"><img src="{{image}}" alt="instagram image"></a>',
            sortBy: 'most-recent',
            limit: 8,
            links: false
        });

        userFeed.run();
    }
};