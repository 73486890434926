'use strict';

// BASICS • Import modules

import {platform} from './includes/platform';
import {materials} from './includes/materials';

// BASICS • Export module

export const basics = () => {
    platform();
    materials();
};