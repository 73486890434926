'use strict';

// PRODUCT • Import module

import Swiper from 'swiper';

// PRODUCT • Export module

export const product = () => {
    slider();
    reviews();
    specification();
};

// PRODUCT • Create slider

const slider = () => {
    let productName = 'product-slider';
    let productElement = $('#' + productName);

    if(productElement) {
        new Swiper('#' + productName, {
            speed: 500,
            spaceBetween: 30,
            slidesPerView: 1,
            pagination: {
                clickable: true,
                el: '.product-pagination'
            }
        });
    }
};

// PRODUCT • Create reviews

const reviews = () => {
    let body = $('body');

    const reviewItems = $('.product-review-item');
    const reviewTouch = $('.product-review-touch');
    const reviewContent = $('.product-review-content');

    const reviewShow = $('.product-review-touch-show');
    const reviewHide = $('.product-review-touch-hide');

    if(reviewItems.length > 3) {
        let set = true;
        let heightShow = 0;
        let heightHide = 0;
        let reviewShowHeight = 0;

        let getHeight = function(){
            heightHide = 0;
            reviewShowHeight = reviewShow.outerHeight();
            heightShow = reviewContent.css('height', 'auto').height();

            for(let i = 0; i < 3; i++) {
                heightHide += reviewItems.eq(i).outerHeight();
            }

            if(set === true) {
                reviewContent.height(heightHide);
                reviewTouch.height(reviewShowHeight);
            }
        };

        getHeight();
        reviewHide.css('display', 'none');

        body.on('click', '#show-reviews', function(){
            set = false;
            reviewShow.fadeOut(300);
            reviewContent.animate({height: heightShow}, 1000, function(){
                reviewHide.fadeIn(300);
                reviewContent.css('height', 'auto');
            });
        });

        body.on('click', '#hide-reviews', function(){
            set = true;
            reviewHide.fadeOut(300);
            reviewContent.animate({height: heightHide}, 1000, function(){
                reviewShow.fadeIn(300);
            });
        });

        $(window).resize(function(){
            getHeight();
        });

    } else {
        reviewTouch.remove();
    }
};

// PRODUCT • Create specification

const specification = () => {
    let allow = true;
    const wrapper = $('.product-info-contents');
    const tabButtons = $('.product-info-tab');
    const tabContents = $('.product-info-content-item');

    $('body').on('click', '.product-info-tab', function() {
        let index = $(this).index();
        let wrapperHeight = wrapper.height();

        allow = false;
        tabButtons.removeClass('active');

        $(this).addClass('active');
        wrapper.addClass('hidden');
        wrapper.height(wrapperHeight);

        setTimeout(function(){
            tabContents.removeClass('active');
            tabContents.eq(index).addClass('active');

            wrapper.css('height', 'auto');
            wrapper.removeClass('hidden');
        }, 500);

        setTimeout(function(){
            allow = true;
        }, 1000);
    });
};