'use strict';

// CONTACT • Export module

export const contact = () => {
    map();
};

// CONTACT • Create map

const map = () => {
    const mapContainer = $('#contact-map');
    const stylesContainer = $('#map-styles');

    const jsonStyles = stylesContainer.html();
    const markerUrl = './build/images/others/marker.png';

    const mapZoom = 6;
    const mapStyles = JSON.parse(jsonStyles);
    const latitude = parseFloat(mapContainer.attr('data-lat'));
    const longitude = parseFloat(mapContainer.attr('data-lng'));

    const mapOptions = {
        center: new google.maps.LatLng(latitude, longitude),
        zoom: mapZoom,
        panControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        styles: mapStyles
    };

    const mapArea = new google.maps.Map(document.getElementById('contact-map'), mapOptions);

    new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map: mapArea,
        visible: true,
        icon: markerUrl
    });
};